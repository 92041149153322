import React from 'react';
import './InputView.css';
import { Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import { SoilForm, SlopeCharacteristics, ForestCharacteristics} from "../InputForms";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RunApplication from "services/RunApplication";
import { useSelector, useDispatch } from 'react-redux';
import { ResultStatus } from 'components/messages'
import { showCalculationProgress, showErrorMessage, setCurrentTab} from 'store/ui/ui.slice';
import { setWASMApplicationClean } from 'store/wasmApplication/wasmApplication.slice';
import { RootState } from 'store';

/**
 * Input View for Application. Shows all input fields and handles submitting the
 * calculation to be ran.
 *
 * @return {React.Component} Returns a react component containing all the input
 * fields. 
 */
function InputView() {
  
  
  const dispatch = useDispatch()
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);
  const calculationSubmitted = useSelector((state: RootState) => state.UI.modal_processing_visible);

  function formOnSubmit(event: React.FormEvent) {
    console.debug("InputView: Form Submittion Event")

    // Update the UI to notify the user their requested action has been started
    //window.scroll({top: 0, left: 0, behavior: 'smooth'});
    
    dispatch(showCalculationProgress(true));
    dispatch(setWASMApplicationClean());

    // Run the calculation asyncronously.
    RunApplication(WASMApplicationState).then((msg) => {
      if (msg.type === ResultStatus.Failure) {
        console.error("Propagating error: ", JSON.stringify(msg));
        throw msg;
      }
      dispatch(showCalculationProgress(false));
      dispatch(setCurrentTab("Soil Thickness"));
      dispatch(setWASMApplicationClean());
    }).catch((errMsg) => {
      console.error("Error Encountered: ", JSON.stringify(errMsg));
      dispatch(showErrorMessage(errMsg.message));
    });
  
    event.preventDefault();
  }

  if (calculationSubmitted) {
    return (
      <Card>
        <Card.Body>
          <div style={{ textAlign: 'center'}}>
            <p>Calculating... Please Wait...</p>
            <Spinner animation='border'/>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div>
      <Form noValidate onSubmit={formOnSubmit}>
        <Container>
          <Row>
            <Col sm={12}>
              <SlopeCharacteristics/>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <SoilForm/>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <ForestCharacteristics/>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Button variant="primary" type="submit" style={{ width: "100%" }}>
                Calculate
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default InputView;
