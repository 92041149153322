import React, {useEffect} from "react";
import Card from "react-bootstrap/Card";
import { NumberField } from "components/forms";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setWASMApplicationState } from 'store/wasmApplication/wasmApplication.slice';
import Table from 'react-bootstrap/Table';
import { GetTreeNames } from 'services/RunApplication';
import SpeciesInputRow from './SpeciesInputRow';
import {specices_map, index_species_map} from 'utils/speciesMap';

export interface IComponentState {
  gammaRootReinforcement: number,
  characteristicValueCoefficient: number
}

export default function SoilThickness() {
  const dispatch = useDispatch()
  const WASMApplicationState = useSelector((state: RootState) => state.WASMApplication);

  const initial_state: IComponentState  = {  
    gammaRootReinforcement: WASMApplicationState.gammaRootReinforcement,
    characteristicValueCoefficient: WASMApplicationState.characteristicValueCoefficient
  }
  
  //@ts-ignore
  const initial_selected_species: string[] = WASMApplicationState.speciesDensity.map((v, i) => {
    if (v <= 0) {
      return undefined;
    }
    return index_species_map.get(i);
  }).filter(v => v !== undefined);

  const [parameters, setParameters] = React.useState<IComponentState>(initial_state);
  const [selected_species, setSelectedSpecies] = React.useState<string[]>(initial_selected_species);
  const [species, setSpecies] = React.useState<string[]>([]);

  /**  
     * Update the local state while the component is changing.
     * @param event HTML Input Event
     */
  function handleTextBoxOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParameters({
      ...parameters,
      [event.target.name]:
        event.target.value === ""
          ? event.target.value
          : parseFloat(event.target.value)
    });
  }

  /**
   * Update global state once the control is unfocused
   * @param event onBlur Event
   */
  function handleNumberFieldOnBlur(event: React.FocusEvent<HTMLInputElement> ) {
    dispatch(setWASMApplicationState({
      ...WASMApplicationState,
      ...parameters
    }));
  }

  /**
   * Upon first load, get the tree names.
   */
  useEffect(() => {
    GetTreeNames().then((msg) => {
      const results = msg.data;
      const species: string[] = [];
      for(let i = 0; i < results.size(); i++) {
        const specie: string = results.get(i);
        const item = specices_map.get(specie);

        // Filter species that we don't have data for
        if (item === undefined || item < 0)
        {
          continue;
        }

        //If item is already in selected, let's not add it here
        if (selected_species.includes(specie)) {
          continue;
        }
        
        species.push(specie);
      }
      species.sort();
      setSpecies(species);
      results.delete();
    });
  }, []);

  function handleTreesOnSelect(eventKey: any, event: React.SyntheticEvent<unknown, Event>): any {
    //@ts-ignore
    const tree_name = event.target.text;
    const v = [...selected_species];
    v.push(tree_name);
    v.sort();
    setSelectedSpecies(v);

    // Remove tree from options
    const v1 = species.filter(s => s !== tree_name);
    v1.sort();
    setSpecies(v1);

    // Update values for initialization
    const index = specices_map.get(tree_name);
    if (index === undefined) {
      console.error(`We have an undefined species: ${species}`);
      throw new Error(`We have an undefined species: ${species}`);
    }
    const speciesDensity = [...WASMApplicationState.speciesDensity];
    speciesDensity[index] = 100;

    const treeDBH = [...WASMApplicationState.treeDBH];
    treeDBH[index] = 10;

    dispatch(setWASMApplicationState({
      ...WASMApplicationState,
      speciesDensity: speciesDensity,
      treeDBH: treeDBH
    }));
  }

  function getTableBody() {
    if (selected_species.length === 0) {
      return <tbody>
          <tr><td>Select a species...</td></tr>
        </tbody>
    }
    selected_species.sort();
    return <tbody>
      {
        selected_species.map((species) => {

          // Get the specie index to use. 
          const index = specices_map.get(species);
          if (index === undefined) {
            console.error(`We have an undefined species: ${species}`);
            throw new Error(`We have an undefined species: ${species}`);
          }

          return (
            <SpeciesInputRow key={species + "input_box"} speciesName={species} speciesIndex={index}/>
          )
        })
      }
    </tbody>
  }

  const form_name = "Forest Characteristics";

  return (
    <Card>
    <Card.Header>
      {form_name}{" "}
    </Card.Header>
    <Card.Body>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <NumberField
              label="Characteristic Value Coefficient"
              unit=""
              step="0.01"
              min={1}
              name="characteristicValueCoefficient"
              value={parameters.characteristicValueCoefficient}
              onChange={handleTextBoxOnChange}
              onBlur={handleNumberFieldOnBlur}
            />
          </Col>
          <Col md={6} sm={12}>

            <NumberField
              label="Partial Safety Factor for Root Reinforcement"
              unit=""
              step="0.01"
              min={1}
              name="gammaRootReinforcement"
              value={parameters.gammaRootReinforcement}
              onChange={handleTextBoxOnChange}
              onBlur={handleNumberFieldOnBlur}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DropdownButton onSelect={handleTreesOnSelect} title="Add Tree Species" disabled={species.length === 0} id="dropdown-basic">
                {
                  species.map((specie, index) => {
                    return <Dropdown.Item eventKey={specie} key={`dropdown-item-${specie}`}>{specie}</Dropdown.Item>
                  })
                }
            </DropdownButton>
          </Col>
        </Row>
        <Table bordered>
          <thead>
            <tr>
              <th>Species</th>
              <th>Density Horizontal Projection [trees/ha]</th>
              <th>DBH [cm]</th>
            </tr>
          </thead>
          { getTableBody() }
        </Table>
       </Container>
    </Card.Body>
  </Card>
  );
}


/*
<Row>
          <Col md={6} sm={12}>
            <NumberField
              label="Saturated Thickness Fraction"
              unit=""
              name="saturatedThicknessFraction"
              value={parameters.saturatedThicknessFraction}
              onChange={handleTextBoxOnChange}
            />
          </Col>
          <Col md={6} sm={12}>
            <NumberField
              label="Rainfall Rate"
              unit="mm/hr"
              name="rainfallRate"
              value={parameters.rainfallRate}
              onChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>*/