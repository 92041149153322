import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';


export interface landslideSideResult {
  area: number,
  fos_veg: number,
  fos_no_veg : number
}

export interface canopyCoverResult {
  dbh: number,
  mean_stand_density: number,
  canopy_cover: number,
}

export interface landslideResult {
  id: number,
  S: landslideSideResult,
  R: landslideSideResult
}

export interface userDefinedValueNormal {
  mean: number,
  stdDev: number,
  gamma: number
}

export interface wasmApplicationState {
  nLandslides: number,
  slope: number ,                          // [deg]
  saturatedThicknessFraction: number,      // [-]
  passiveEarthPressureCorrection: number,  // [%]
  invGammaShape: number,                   // [-]
  invGammaScale: number,                   // [km^2]
  invGammaS: number,                       // [km^2]
  elevation: number,
  aspectDeg: number,
  t0: number,

  // Soil
  soilType: string,
  cohesion: userDefinedValueNormal,
  densityDry: userDefinedValueNormal,
  frictionAngle: userDefinedValueNormal,
  vgWetAlpha: number,                          // [Pa-1] van Genuchten alpha parameter related to the inverse of the air entry suction (>0)
  vgWetN: number,                              // [-]    van Genuchten n parameter related to the pore-size distribution (>1)
  fieldCapacityPressure: number             // [Pa] Pressure value at which to compute field capacity

  // Soil thickness. Mean and standard deviation only
  soilthickness_mean: number,
  soilthickness_sd: number,

  // Vegetation data
  species: string[],
  speciesDensity: number[],               // [trees per hectare]
  treeDBH: number[],                         // [cm]

  gammaRootReinforcement: number,
  maxLandslideArea: number,
  characteristicValueCoefficient: number,
  dirty: Boolean
}

const initialState: wasmApplicationState = {
  nLandslides: 10000,
  slope: 28,                          // [deg]
  saturatedThicknessFraction: 1,      // [-]
  passiveEarthPressureCorrection: 30, // [%]

  invGammaShape: 1.66,          // [-]
  invGammaScale: 0.00020415,     // [km^2]
  invGammaS: 2.002259e-08,          // [km^2]

  maxLandslideArea: 2000,

  elevation: 1200,
  aspectDeg: 0,
  t0: 20,


  // Soil
  soilType: "UserDefined",
  //KiLib::Soils::UserDefined            userSoil;
  cohesion: {
    mean: 0.5,
    stdDev: 0.1,
    gamma: 1
  },
  densityDry: {
    mean: 1500,
    stdDev: 100,
    gamma: 1
  },
  frictionAngle: {
    mean: 36,
    stdDev: 5,
    gamma: 1
  },
  vgWetAlpha: 1E-04,                      // [Pa-1] van Genuchten alpha parameter related to the inverse of the air entry suction (>0)
  vgWetN: 3,                              // [-]    van Genuchten n parameter related to the pore-size distribution (>1)
  fieldCapacityPressure: 33000,        // [Pa] Pressure value at which to compute field capacity


  // Soil thickness. Mean and standard deviation only
  soilthickness_mean: 1,              // [m]
  soilthickness_sd: 0.3,              // [m]


  // Vegetation data
  species: ["AilanthusAltissima", "BetulaPendula", "CastaneaSativa", "FagusSylvatica", "PiceaAbies", "PinusRadiata", "PinusSylvestris", "PopulusNigra", "QuercusPubescens", "TrachycarpusFortunei" ],
  speciesDensity: [0, 0, 0, 0, 0, 0, 0, 0,0, 0 ],              // [trees per hectare]
  treeDBH: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],                        // [cm]

  // Partial security factors
  gammaRootReinforcement: 1,
  characteristicValueCoefficient: 1.25,
  dirty: false
};

export const wasmApplicationSlice = createSlice({
  name: 'WASMApplication',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWASMApplicationState: (state, action: PayloadAction<wasmApplicationState>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      action.payload.dirty = true;
      return action.payload;
    },

    // When the calculation is running, update state properly
    resetWASMApplicationDefaultState: (state) => {
      state = initialState;
    },

    setWASMApplicationClean: (state) => {
      state.dirty = false;
    }
  }
});

export const { setWASMApplicationState, resetWASMApplicationDefaultState, setWASMApplicationClean } = wasmApplicationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getWASMApplicationState = (state: RootState) => state.WASMApplication;

export default wasmApplicationSlice.reducer;

export { initialState };